<template>
  <el-card shadow="never" style="border-bottom: none" v-loading="loading">
    <el-tabs v-model="activeName">
      <el-tab-pane label="未发货" name="first">
        <el-table
          :data="orderDetails.noList"
          size="small"
          row-key="id"
          :default-expand-all="false"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column label="商品名称" prop="name">
            <template slot-scope="scope">
              <div
                class="demo-image__preview"
                style="display: flex; align-items: center"
              >
                <el-image
                  style="width: 36px; height: 36px; margin-right: 5px"
                  :src="scope.row.goodsSkuImg ? scope.row.goodsSkuImg : moren"
                  :preview-src-list="[
                    scope.row.goodsSkuImg ? scope.row.goodsSkuImg : moren,
                  ]"
                />
                <div>
                  <div>{{ scope.row.goodsName }}</div>
                  <div>规格：{{ scope.row.goodsSkuName }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="数量" prop="quantity">
            <template slot-scope="scope">
              <div v-if="!isShow">{{ scope.row.quantity }}</div>
              <el-input-number
                v-model="scope.row.quantityCopy"
                v-else
                :min="1"
                :max="scope.row.quantity"
                label=""
              ></el-input-number>
            </template>
          </el-table-column>

          <el-table-column label="售后状态">
            <template slot-scope="scope">
              {{
                scope.row.refundStatus == 0
                  ? "未申请"
                  : scope.row.refundStatus == 1
                  ? "进行中"
                  : scope.row.refundStatus == 2
                  ? "部分完成"
                  : scope.row.refundStatus == 3
                  ? "申请完成"
                  : scope.row.refundStatus == 4
                  ? "申请失败 "
                  : ""
              }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="已发货" name="two">
        <el-table
          :data="orderDetails.yesList"
          size="small"
          row-key="id"
          :default-expand-all="false"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column label="商品名称" prop="name">
            <template slot-scope="scope">
              <div
                class="demo-image__preview"
                style="display: flex; align-items: center"
              >
                <el-image
                  style="width: 36px; height: 36px; margin-right: 5px"
                  :src="scope.row.goodsSkuImg ? scope.row.goodsSkuImg : moren"
                  :preview-src-list="[
                    scope.row.goodsSkuImg ? scope.row.goodsSkuImg : moren,
                  ]"
                />
                <div>
                  <div>{{ scope.row.goodsName }}</div>
                  <div>规格：{{ scope.row.goodsSkuName }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="数量" prop="shippingQuantity">
          </el-table-column>
          <el-table-column label="售后状态">
            <template slot-scope="scope">
              {{
                scope.row.refundStatus == 0
                  ? "未申请"
                  : scope.row.refundStatus == 1
                  ? "进行中"
                  : scope.row.refundStatus == 2
                  ? "部分完成"
                  : scope.row.refundStatus == 3
                  ? "申请完成"
                  : scope.row.refundStatus == 4
                  ? "申请失败 "
                  : ""
              }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <div v-if="activeName=='two'">
      <el-radio-group
        v-model="tabPosition"
        style="margin-bottom: 30px; margin-top: 20px"
      >
        <el-radio-button
          :label="index"
          v-for="(item, index) in orderDetails.orderShippings"
          :key="index"
          >{{ "包裹" + (index + 1) }}</el-radio-button
        >
      </el-radio-group>
      <el-descriptions
        :column="1"
        v-if="
          orderDetails.orderShippings && orderDetails.orderShippings.length > 0
        "
      >
        <!-- <el-descriptions-item label="商品名称">{{
                  orderDetails.orderShippings[tabPosition].goodsName
                }}</el-descriptions-item> -->

        <el-descriptions-item label="商品名称">
          <div
            v-for="(item, index) in orderDetails.orderShippings[tabPosition]
              .shippingItems"
            :key="index"
            style="margin-bottom: 10px"
          >
            <el-tag type="info">{{ item.goodsName }}</el-tag>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="物流公司">{{
          orderDetails.orderShippings[tabPosition].expressCompany
        }}</el-descriptions-item>
        <el-descriptions-item label="物流单号">{{
          orderDetails.orderShippings[tabPosition].expressNo
        }}</el-descriptions-item>
        <el-descriptions-item label="发货时间">{{
          orderDetails.orderShippings[tabPosition].shippingTime
        }}</el-descriptions-item>
        <el-descriptions-item label="收货地址"
          >{{
            orderDetails.shippingProvince +
            orderDetails.shippingCity +
            orderDetails.shippingDistrict +
            "  "
          }}{{ orderDetails.shippingAddress }}</el-descriptions-item
        >
      </el-descriptions>
      <el-steps
        direction="vertical"
        :active="1"
        :space="75"
        v-if="
          orderDetails.orderShippings && orderDetails.orderShippings.length > 0
        "
      >
        <el-step
          v-for="(i, index) in orderDetails.orderShippings[tabPosition]
            .expressLists"
          :key="index"
          :title="i.AcceptStation"
          :description="i.AcceptTime"
          :icon="true ? 'el-icon-document-checked' : 'el-icon-success'"
        ></el-step>
      </el-steps>
      <div v-else style="text-align: center; font-size: 14px">无需物流</div>
    </div>
  </el-card>
</template>
<script>
import { shippingDetail } from "@/api/supplierGoods/order";
import { expressLogistics } from "@/api/supplierGoods/order";
export default {
  name: "Info",
  props: {
    orderId: {
      type: String,
    },
  },
  data() {
    return {
      orderDetails: {
        orderShippings: [],
      },
      isShow: false,
      activeName: "first",
      tabPosition: 0,
      loading: false,
    };
  },
  created() {
    if (this.orderId) {
      this.getOrderDetails();
    }
  },
  methods: {
    getOrderDetails() {
      this.loading = true;
      shippingDetail({ orderId: this.orderId })
        .then((res) => {
          res.data.noList = [];
          res.data.yesList = [];
          res.data.orderItems.forEach((item) => {
            if (item.quantity != item.shippingQuantity) {
              let q = item.quantity - item.shippingQuantity;
              res.data.noList.push({ ...item, quantity: q, quantityCopy: q });
            }

            if (item.shippingQuantity > 0) {
              res.data.yesList.push({ ...item });
            }
          });
          this.expressLogistics(res.data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async expressLogistics(datas) {
      this.loading = true;
      let expressListsNum = 0;
      console.log("1231", datas);
      datas.orderShippings.map(async (item) => {
        if (item.id) {
          let { data } = await expressLogistics({ type: 1, id: item.id });
          item.expressLists =
            data.traces !== "暂无轨迹信息" ? JSON.parse(data.traces) : [];
          expressListsNum = expressListsNum + 1;
          if (expressListsNum == datas.orderShippings.length) {
            this.orderDetails = datas;
            this.$forceUpdate();
            this.loading = false;
          }
        }
      });
      // console.log('123expressListsNum',expressListsNum)
      // if(expressListsNum ==  datas.orderShippings.length){
      //   console.log('123')
      //   this.orderDetails = datas
      //   this.$forceUpdate()
      //   this.loading = false
      // }
      setTimeout(() => {
        this.orderDetails = datas;
        this.$forceUpdate();
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 40px;
}
/deep/.el-form {
  margin-top: 30px;
}
.el-switch {
  margin-bottom: 30px;
}
</style>
