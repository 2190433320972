<template>
  <div>
    <el-card class="box-card">
      <div class="infoBox">
        <div class="title">订单编号:</div>
        {{ details.code }}
      </div>
      <div class="infoBox">
        <div class="title">订单状态:</div>
        {{ state[details.status] }}
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">订单信息</div>
      <div class="infoBox">
        <div class="title">收货人姓名:</div>
        {{ details.shippingName }}
      </div>
      <div class="infoBox">
        <div class="title">收货人电话:</div>
        {{ details.shippingMobile }}
      </div>
      <div class="infoBox">
        <div class="title">收货人地址:</div>
        {{
          details.shippingProvince +
          details.shippingCity +
          details.shippingDistrict +
          details.shippingAddress
        }}
      </div>
      <div class="infoBox">
        <div class="title">订单金额:</div>
        {{ details.orderAmount }}
      </div>
      <div class="infoBox">
        <div class="title">实付金额(元）:</div>
        {{ details.payAmount }}
      </div>
      <div class="infoBox">
        <div class="title">积分抵扣(元):</div>
        {{ details.scoreAmount }}
      </div>
      <div class="infoBox">
        <div class="title">手机号:</div>
        {{ details.phone }}
      </div>
      <div class="infoBox" v-if="details.payAt">
        <div class="title">支付时间:</div>
        {{ details.payAt }}
      </div>
      <div class="infoBox">
        <div class="title">下单时间:</div>
        {{ details.orderTime }}
      </div>
      <div class="infoBox">
        <div class="title">买家留言:</div>
        {{ details.remark }}
      </div>
      <div class="infoBox">
        <div class="title">卖家备注:</div>
        {{ details.reply }}
      </div>
      <div class="infoBox" v-if="details.closedReason">
        <div class="title">关闭原因:</div>
        {{ details.closedReason }}
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">子订单</div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        class="table"
        row-key="id"
      >
        <el-table-column type="index" width="100" label="序号" />
        <el-table-column label="订单号" prop="code" min-width="170" />
        <el-table-column label="订单金额" prop="orderAmount" min-width="170" />
        <el-table-column label="下单时间" prop="orderTime" min-width="150" />
        <el-table-column label="订单状态" min-width="130">
          <template slot-scope="scope">
            <div>
              {{ state[scope.row.status] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-info"
              @click="onOrderDetails(scope.row)"
              >订单商品详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog title="商品详情" :visible.sync="dialogVisible" width="80%">
      <el-table
        v-loading="listLoading"
        :data="shopList"
        size="small"
        class="table"
        row-key="id"
      >
        <el-table-column type="index" width="100" label="序号" />
        <el-table-column label="商品图" min-width="170">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.goodsImg"
                :preview-src-list="[scope.row.goodsImg]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" min-width="170" />
        <el-table-column label="商品SKU图片" min-width="170">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.goodsSkuImg"
                :preview-src-list="[scope.row.goodsSkuImg]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="商品SKU编码"
          prop="outerSkuCode"
          min-width="170"
        />
        <el-table-column
          label="商品SKU名称"
          prop="goodsSkuName"
          min-width="170"
        />
        <el-table-column label="原价" prop="originalPrice" min-width="170" />
        <el-table-column label="售价" prop="salePrice" min-width="170" />
        <el-table-column label="商品数量" prop="quantity" min-width="170" />
        <el-table-column label="应收" prop="salePrice" min-width="170">
          <template slot-scope="scope">
            {{ Number(scope.row.salePrice * scope.row.quantity).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="扣减积分" prop="scorePrice" min-width="170" />
        <el-table-column label="优惠金额" prop="couponPrice" min-width="170" />
        <el-table-column label="现金支付" prop="buyPrice" min-width="170" />
        <el-table-column label="发货状态" align="center">
          <template slot-scope="scope">
            {{ scope.row.shippingStatus === 0 ? "未发货" : "已发货" }}
          </template>
        </el-table-column>
        <el-table-column label="售后状态" min-width="130">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.refundStatus == 0
                  ? "未申请 "
                  : scope.row.refundStatus == 1
                  ? "进行中 "
                  : scope.row.refundStatus == 2
                  ? "部分完成"
                  : scope.row.refundStatus == 3
                  ? "申请完成"
                  : scope.row.status == 4
                  ? "取消售后"
                  : "-"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          prop="quantity"
          min-width="170"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.refundStatus != 0 && scope.row.refundStatus != 4"
              size="small"
              icon="el-icon-info"
              @click="afterDetails(scope.row)"
              >售后详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <ReturnOrderDetails
      v-if="afterDetailsShow"
      @closeDrawer="closeDrawer"
      :drawer="afterDetailsShow"
      :orderId="orderId"
      ref="returnOrderDetails"
    />

    <el-card class="box-card">
      <div slot="header" class="clearfix">物流信息</div>
      <LogisticsDetailsVue v-if="details.id" :orderId="details.id" />
    </el-card>
  </div>
</template>
<script>
import { ordersPageList, ordersDetail, orderShipping } from "@/api/order/order";
import ReturnOrderDetails from "./components/returnOrderDetails.vue";
import LogisticsDetailsVue from "./components/logisticsDetails.vue";
import { state } from "./orders";
export default {
  name: "orderDetails",
  components: {
    ReturnOrderDetails,
    LogisticsDetailsVue,
  },
  data() {
    return {
      state,
      id: "",
      listLoading: false,
      details: {},
      tableData: {
        data: [],
      },
      dialogVisible: false,
      orderId: "",
      afterDetailsShow: false,
      shopList: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    afterDetails(row) {
      this.$router.push({
        path: "/order/returnOrderInfo",
        query: {
          orderId: row.refundId,
        },
      });
    },
    // afterDetisl(id){
    //   this.$router.push({
    //     path: "/order/returnOrderInfo",
    //     query: {
    //       orderId: row.refundId,
    //     },
    //   });
    // },
    // 列表
    getDetails() {
      this.listLoading = true;
      ordersDetail({ orderId: this.id })
        .then((res) => {
          this.details = res.data;
          this.tableData.data = res.data.ordersSubs;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    closeDrawer() {
      this.afterDetailsShow = false;
    },
    onOrderDetails(row) {
      this.dialogVisible = true;
      this.shopList = row.orderItems;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  margin-bottom: 20px;
}
.infoBox {
  display: flex;
  margin: 12px 0px;
}
.title {
  width: 120px;
}
</style>
