import { get, put, Delete, post } from '../axios'

// 订单列表
export const ordersPageList = params => get(`/order/web/orders/pageList`, params);
// 订单详情
export const ordersDetail = params => get(`/order/web/orders/detail`, params);
// 订单发货
export const orderShipping = params => post(`/order/web/orders/shipping`, params);

// 供应商订单列表
export const ordersSubPageList = params => get(`/order/web/orders/subPageList`, params);
// 供应商订单详情
export const ordersSubDetail = params => get(`/order/web/orders/subDetail`, params);
//平台-供应商商品订单-查看
export const supplierOrderDetail = params => get(`/order/web/finance/platform/supplier/order/detail`, params);
//平台-门店商品订单-查看
export const storeOrderDetail = params => get(`/order/web/finance/platform/store/order/detail`, params);
//门店商品订单-查看
export const OrderDetailByStore = params => get(`/order/web/finance/store/order/detail`, params);
//供应商商品订单-查看
export const OrderDetailBySupplier = params => get(`/order/web/finance/supplier/order/detail`, params);
//订单回复
export const ordersReply = params => post(`/order/web/orders/reply`, params);
//订单回复
export const ordersCount = params => get(`/order/web/orders/count`, params);
//订单回复
export const ordersSubCount = params => get(`/order/web/orders/subCount`, params);
//查看订单评价 - 总平台 门店
export const ordersComments = params => get(`/order/web/orders/comments`, params);
//查看订单评价 - 供应商
export const ordersubComments = params => get(`/order/web/orders/subComments`, params);




